<template>
  <el-row class="widget-preview">
    <el-col :span="24">
      <el-row>
        <el-col :span="24">
          <el-divider />
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="10" class="m-b-10">
        <el-col :xl="2" :lg="2" :md="3" :sm="4" :xs="5">
          <h3 class="m-b-5 m-t-5">{{ $t("preview") }}</h3>
        </el-col>
        <el-col :xl="4" :lg="4" :md="5" :sm="7" :xs="9">
          <el-radio-group v-model="activeTabSize">
            <el-radio-button
              label="desktop"
            ><i class="el-icon-monitor" />
            </el-radio-button>
            <el-radio-button
              label="tablet"
            ><i class="el-icon-mobile-phone" />
            </el-radio-button>
            <el-radio-button
              label="mobile"
            ><i
              class="el-icon-mobile"
            /></el-radio-button>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row>
        <div class="display-size">
          <div
            v-if="previewData.generalStep"
            id="tripup-widget-container"
            :class="[
              'display-size__preview',
              'display-size__preview--' + activeTabSize,
            ]"
            :data-customer="previewData.generalStep.customerId"
            :data-eyecatcher="previewData.generalStep.showEyecatcher ? '' : 'false'"
            :data-channel="previewData.generalStep.channel"
            :data-cruisetype="previewData.generalStep.cruiseType"
            :data-components="String(previewData.generalStep.components)"
            :data-port_id="previewData.startDateStep.widgetPortId"
            :data-ship="previewData.startDateStep.cruiseShipName"
            :data-date="previewData.startDateStep.cruiseDate"
            :data-duration="previewData.startDateStep.cruiseDuration"
            :data-destination="previewData.startDateStep.destination"
            :data-tags="previewData.filterStep.tags"
            :data-limit="previewData.filterStep.limit"
            :data-shortproducts="previewData.filterStep.shortProducts"
            :data-skus="previewData.startDateStep.productIds"
          />
        </div>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import HostNameService from './../services/HostNameService';

export default {
  name: 'WidgetPreview',
  props: {
    previewData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: function() {
    return {
      activeTabSize: 'desktop',
    };
  },
  watch: {
    previewData: {
      handler() {
        this.restartWidget();
      },
      deep: true,
    },
  },
  methods: {
    restartWidget() {
      const container = document.getElementById('tripup-widget-container');
      if (container) {
        container.innerHTML = '';
      }

      const script = document.getElementById('tripup-widget-script');
      if (script) {
        script.remove();
      }

      const script2 = document.createElement('script');
      script2.src = HostNameService.getUrlWidget() + '/itinerary/js/main.min.js';
      script2.id = 'tripup-widget-script';
      script2.setAttribute(
        'data-containers',
        'tripup-widget-container',
      );
      document.head.appendChild(script2);
    },
    clearContainer() {
      return new Promise(function(resolve) {
        const container = document.getElementById('tripup-widget-container');
        if (container) {
          container.innerHTML = '';
        }
        resolve();
      });
    },
    removeStarterScript() {
      return new Promise(function(resolve) {
        const script = document.getElementById('tripup-widget-script');
        if (script) {
          script.onreadystatechange = resolve;
          script.onload = resolve;
          script.remove();
        } else {
          resolve();
        }
      });
    },
    addStarterScript() {
      return new Promise(function(resolve) {
        const script = document.createElement('script');
        script.src = HostNameService.getUrlWidget() + '/itinerary/js/main.min.js';
        script.id = 'tripup-widget-script';
        script.setAttribute(
          'data-containers',
          'tripup-widget-container',
        );
        script.onreadystatechange = resolve;
        script.onload = resolve;
        document.head.appendChild(script);
      });
    },
  },
};
</script>
