class HostNameService {
  shop = 'meine-landausfluege.de';
  widget = 'widget';
  apiSelected = 'api';
  protocol = window.location.protocol;

  constructor() {
    const hostname = window.location.hostname,
      i = hostname.indexOf('.');

    this.widget = hostname.slice(0, i).trim();
    this.apiSelected = this.widget === 'devwidget' ? 'devapi' : 'api';
    this.shop = hostname.slice(i + 1, hostname.length).trim();
  }

  setShop(data) {
    this.shop = data;
  }

  getShop() {
    return this.shop;
  }

  getUrlWidget() {
    if (process.env.VUE_APP_WIDGET_URL) {
      return process.env.VUE_APP_WIDGET_URL;
    }
    return this.protocol + '//' + this.widget + '.' + this.shop;
  }

  getUrlApi() {
    if (process.env.VUE_APP_API_URL) {
      return process.env.VUE_APP_API_URL;
    }
    return this.protocol + '//' + this.apiSelected + '.' + this.shop;
  }
}

export default new HostNameService();
