import HostNameService from '@/services/HostNameService';

export default {
  create: function(data, activeTabStart) {
    let result = '<div id="widget-main-tripup"';

    if (data.generalStep.customerId) {
      result += ' data-customer="' + data.generalStep.customerId + '"';
    }
    if (!data.generalStep.showEyecatcher) {
      result += ' data-eyecatcher="false"';
    }
    if (data.generalStep.channel) {
      result += ' data-channel="' + data.generalStep.channel + '"';
    }

    if (data.generalStep.cruiseType) {
      result += ' data-cruisetype="' + data.generalStep.cruiseType + '"';
    }

    result += ' data-components="' + String(data.generalStep.components) + '"';

    if (activeTabStart === 0) {
      if (data.startDateStep.widgetPortId) {
        result += ' data-port_id="' + data.startDateStep.widgetPortId + '"';
      }
    } else if (activeTabStart === 1) {
      if (data.startDateStep.cruiseShipName) {
        result += ' data-ship="' + data.startDateStep.cruiseShipName + '"';
      }
      if (data.startDateStep.cruiseDate) {
        result += ' data-date="' + data.startDateStep.cruiseDate + '"';
      }
      if (data.startDateStep.cruiseDuration) {
        result += ' data-duration="' + data.startDateStep.cruiseDuration + '"';
      }
    } else if (activeTabStart === 2) {
      if (data.startDateStep.destination) {
        result += ' data-destination="' + data.startDateStep.destination + '"';
      }
    } else if (activeTabStart === 3) {
      if (data.startDateStep.productIds) {
        result += ' data-skus="' + data.startDateStep.productIds + '"';
      }
    }

    if (data.filterStep.tags) {
      result += ' data-tags="' + data.filterStep.tags + '"';
    }
    if (data.filterStep.limit) {
      result += ' data-limit="' + data.filterStep.limit + '"';
    }

    result +=
      ' data-shortproducts="' +
      data.filterStep.shortProducts + '"';

    result += '></div>';

    result +=
      ' <script async defer type="text/javascript"' +
      ' data-containers="widget-main-tripup"' +
      ' src="' +
      HostNameService.getUrlWidget() +
      '/itinerary/js/main.min.js"></script>';
    return result;
  },
};
