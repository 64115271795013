export default {
  'el.select.noData': 'no data',

  select: 'Auswählen',
  previousStep: 'Vorheriger Schritt',
  nextStep: 'Nächster Schritt',

  /* header  */
  widgetHeader: 'Widget Code Generator für Meine Landausflüge',
  imprint: 'Impressum',
  dataProtection: 'Datenschutz',
  simpleMethodDescription:
    'Unsere <b>Online-Integration für Ihre Vermittlung von Landausflügen</b> können Sie schnell, einfach und <b>ohne Programmierkenntnisse</b> auf Ihrer Website einbinden. Folgen Sie einfach unserer Schritt-für-Schritt Anleitung. Der <b>Aufwand dauert maximal 5 Minuten</b>. Im Anschluss müssen Sie lediglich den individuell erstellten Code auf Ihrer Webseite einbinden.',
  pleaseContactUsShort:
    "Falls Sie noch keine <b>Partner-ID</b> oder Fragen haben, <a href='//meine-landausfluege.de/partnerprogramm/'>kontaktieren Sie uns bitte</a>.",

  /* main  */
  general: 'Allgemein',
  startDates: 'Startdaten',
  filter: 'Filter',
  code: 'Code',

  /* form wizard  */
  selectedFieldsRequiredMessage: 'Eine Auswahl ist erforderlich',

  /* general step  */
  partnerId: 'Partner ID',
  shop: 'Shop',
  channel: 'Channel',
  cruiseType: 'Kreuzfahrttyp',
  allCruises: 'Alle Kreuzfahrten',
  onlySea: 'Hochseekreuzfahrt',
  onlyRiver: 'Flusskreuzfahrt',
  showSearch: 'Suche anzeigen',
  showEyecatcher: 'Vorteile anzeigen',
  partnerIdRequiredMessage: 'Partner ID ist erforderlich',

  /* start dates step  */
  portSearch: 'Hafen',
  cruiseSearch: 'Kreuzfahrt',
  destinationSearch: 'Destination',
  productsSearch: 'Produkte',
  destination: 'Destination',
  portSelect: 'Hafen auswählen',
  portsSelect: 'Häfen auswählen',
  cruiseCompanySelect: 'Reederei auswählen',
  cruiseShipSelect: 'Schiff auswählen',
  cruiseSelect: 'Reise auswählen',
  productsSelect: 'Produkte auswählen',
  days: 'Tage',

  /* filter step  */
  limit: 'Produktanzahl',
  shortProducts: 'Nur wenige Produkte (3 - 5) anzeigen und mehr mit dem Klick nachladen',
  top: 'Top',
  sale: 'Aktion',
  new: 'Neu',
  private: 'Private',
  onYourOwn: 'Auf eigene Faust',
  tags: 'Nur Ausflüge mit dem Label:',

  /* widget preview  */
  preview: 'Vorschau',

  /* help tooltip */
  generalStepHelp:
    '<p>Hier können Sie die allgemeinen Einstellungen vornehmen.</p> ' +
    '<p><b>Partner ID</b>:</p>' +
    '<p>- Ihre Partner ID bekommen Sie von unserer Partner-Abteilung, wenn Sie einen Vertrag unterschrieben haben. Beispiel ID: TA123456. Bitte geben Sie eine vollständige ID beginnend mit "TA" ein.</p>' +
    '<p><b>Channel</b>:</p>' +
    '<p>- Hier können Sie ein beliebiges Kürzel frei eingeben, um bei Ihren Abrechnungen und Buchungsstatistiken am Ende genau sehen zu können, welche Buchungen über dieses Widget generiert wurden. Diese Kennzeichnung empfiehlt sich, wenn Sie mehrere Einbindungen integriert haben und/oder zusätzlich mit Bannern oder unserem Buchungslinkversand arbeiten.</p>' +
    '<p><b>Shop</b>:</p>' +
    '<p>- Wenn Sie eine Webseite in der Schweiz betreiben und die Preise in CHF benötigen, können Sie hier die Schweizer Domain einstellen.</p>' +
    '<p><b>Kreuzfahrt-Typ</b>:</p>' +
    '<p>- Wir bieten Ausflüge für Hochsee- und Flusskreuzfahrten an. Sie können das Widget auf einen bestimmten Typ begrenzen. Dabei werden in der Widget-Suche nur bestimmte Kreuzfahrten bzw. Häfen angezeigt.</p>' +
    '<p><b>Suche anzeigen</b>:</p>' +
    '<p>- Wenn Sie „Suche anzeigen“ anklicken, erscheint oberhalb Ihrer ausgewählten Widget Start Seite (z.B. bestimmter Hafen oder Route) ein zusätzliches Suchfeld. Hier kann der Kunde unabhängig von der Wahl Ihrer Startseite jederzeit im oberen Suchformular weitere Routen und Häfen für Landausflüge auswählen.</p>',
  startDateStepHelp:
    '<p>Sie können zwischen 3 verschiedenen Startseiten wählen. Ihr Kunde kann unabhängig von der Wahl Ihrer Startseite jederzeit im oberen Suchformular weitere Routen und Häfen auswählen, sollten Sie das Suchfeld angeklickt haben unter „Allgemein“</p>' +
    '<p><b>Hafen</b>:</p>' +
    '<p>- Es werden nur Ausflüge zu einem bestimmten Hafen angezeigt.</p>' +
    '<p>- Diese Widget-Startseite empfiehlt sich in 1. Linie für eine Homepage, die in verschiedene Hafenseiten unterteilt ist, sodass man pro Hafenseite das passende Hafen-Widget einbinden und den Kunden thematisch am besten abholen kann.</p>' +
    '<p><b>Kreuzfahrt</b>:</p>' +
    '<p>- Es werden nur die Ausflüge zu einer bestimmten Kreuzfahrtroute angezeigt.</p>' +
    '<p>- Diese Einstellung passt am besten, um den Fokus auf eine Topseller-Route zu lenken. Außerdem eignet sich die Einbindung auf einer Webseite mit der Beschreibung zu einer konkreten Kreuzfahrtroute oder einem Schiff.</p>' +
    '<p>- Diese Einstellung passt am besten, um das Widget auf einer Webseite mit der Beschreibung zu einer Kreuzfahrt oder einem Schiff anzuzeigen.</p>' +
    '<p><b>Destination</b>:</p>' +
    '<p>- Es werden nur Ausflüge aus verschiedenen Häfen in einer bestimmten Destination (z.B. Kanarische Inseln) angezeigt.</p>' +
    '<p>- Im folgenden Schritt 3 „Eingrenzen“ haben Sie anschließend die Möglichkeit, nur die von uns gekennzeichneten Top Ausflüge aus verschiedenen Häfen in Ihrer ausgewählten Destination anzeigen zu lassen.</p>',
  filterStepHelp1:
    '<p>Hier können Sie noch einige ergänzende Einstellungen machen.</p>' +
    '<p>Nur Ausflüge mit dem Label:</p>' +
    '<p>-„Meine Landausflüge“ kennzeichnet ausgewählte Ausflüge mit entsprechenden Tags:</p>' +
    '<p><ul><li>Top– unsere Bestseller</li></p>' +
    '<p><li>Aktion– Ausflüge, die gerade reduziert sind</li></p>' +
    '<p><li>Neu– Ausflüge, die kürzlich neu ins Portfolio mit aufgenommen wurden</li></p>' +
    '<p><li>Privat– unsere Exklusivausflüge</li></p>' +
    '<p><li>Auf eigene Faust– Ausflüge ohne Transfer und Pünktlich-zurück-zum-Schiff Garantie</li></ul></p>' +
    '<p>Bitte beachten Sie, dass sich bei Auswahl von Tags die Anzahl der Ausflüge auf der Widget-Startseite deutlich reduziert.</p>',
  filterStepHelp2:
    '<p><b>Nur wenige Produkte anzeigen (3 - 5) und mehr mit dem Klick nachladen:</b></p>' +
    '<p>- Manchmal können es viele Ausflüge sein. Um den Platz auf der Webseite zu sparen, kann man 3 bis 5 Produkte anzeigen lassen und andere verstecken. Mit einem Knopf unter den Produkten kann der Kunde alle Produkte anzeigen.</p>',
  filterStepHelp3:
    '<p><b>Anzahl maximaler Ausflüge:</b></p>' +
    '<p>- Nur maximale Anzahl von Ausflügen anzeigen.</p>',
  codeStepHelp:
    '<p>Kopieren Sie diesen Code und fügen ihn in Ihre Webseite ein. Wenn Sie eine CMS (z.B. wordpress) benutzen, verwenden Sie bitte den Code-Editor, um den Code in die Seite einzufügen. Andernfalls würde dieser nur als Text auf der Seite ausgegeben werden.</p>',
};
